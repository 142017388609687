<template>
  <div class="view-wrapper">
    <v-stepper v-model="step">
      <v-stepper-header style="max-height: 48px; justify-content: start !important;">
        <template v-for="n in steps">
          <v-stepper-step
              :key="`${n}-step`"
              :complete="step > n"
              :step="n"
              color="success"
              style="max-height: 24px"
          >
            {{$localize(stepTitles[n-1])}}
          </v-stepper-step>
          <v-divider
              v-if="n !== steps"
              :key="n"
              style="max-width: 100px"
          ></v-divider>
        </template>
        <v-spacer/>
        <v-row no-gutters class="align-center justify-end pr-4">
          <v-btn small text class="px-2" @click="stepBack()">{{ $localize('back') }}</v-btn>
          <v-btn small outlined color="success" class="mx-2 px-6" @click="nextStep()">{{ sendRequestInscription ? $localize('send_a_request') : $localize('next') }}</v-btn>
          <v-btn small color="success" @click="sendRequest(true)">{{ $localize('save_as_draft')}}</v-btn>
        </v-row>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content :step="1" class="pa-0 pb-8 mb-8 overflow-auto">
          <Questionnaire ref="questionnaire"
                         :itemProp="questionnaire"
                         :activityTypes="activityTypes"/>
        </v-stepper-content>
        <v-stepper-content :step="2" class="pa-0 pb-8 mb-8 ">
          <Dictionnaries :root="dictRoot"
                         :types="dictTypes"
                         @addItem="addDictItem"
                         @editItem="editDictItem"
                         @removeItem="removeDictItem"
                         @restoreItem="restoreDictItem"
          />
        </v-stepper-content>
        <v-stepper-content :step="3" class="pa-0">
          <v-card>
            <v-card-title class="flex-nowrap py-0">
              <v-tabs
                  ref="tabs"
                  v-model="tab"
                  style="position:sticky; top: 0; bottom: 0; z-index: 1;"
                  show-arrows
                  color="success"
              >
                <v-tab>{{ $localize(tabItems[0].tabLabel) }}</v-tab>
                <v-tab :disabled="!isBalanceMatch">{{ $localize(tabItems[1].tabLabel) }}</v-tab>
              </v-tabs>
              <v-spacer/>
              <v-btn small text @click="periodDialog = true">
                <v-row class="justify-center align-center ml-1 mr-1">
                  <v-icon>mdi-calendar</v-icon>
                  <span class="ml-1">{{ $localize('edit_period') }}</span>
                </v-row>
              </v-btn>
            </v-card-title>
          </v-card>
          <v-card-text class="tab-card-actions pa-0">
            <v-tabs-items v-model="tab" class="pa-0 ma-0 tab-table-1">
              <v-tab-item eager class="mb-4 width-adaptive-table">
                <RequestBalance ref="balance"
                                class="elevation-8"
                                :balanceProp="balance"
                                @setLoading="setLoading"
                                @balanceUpdating="balanceUpdating"
                />
              </v-tab-item>
              <v-tab-item eager class="mb-4 width-adaptive-table">
                <RequestProfit ref="profit"
                               class="elevation-8"
                               :profitProp="profit"
                               @setLoading="setLoading"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-dialog v-model="periodDialog"
              max-width="520px"
              persistent
    >
      <PeriodSelection
          :setCancel="closePeriodDialog"
          :setOk="updateHeader"
          @setPeriod="setPeriod"
      />
    </v-dialog>
    <v-dialog v-model="alertDialog.show"
              max-width="520px"
              persistent
    >
      <v-card>
        <v-card-title dense>
          {{ alertDialog.isQuestionnaireNotFilled ? $localize('fill_the_necessary_fields') : $localize('attention_the_balance_does_not_converge')}}
        </v-card-title>
        <v-card-actions class="align-center justify-center">
          <v-btn @click="closeAlert">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Dictionnaries from "./components/Dictionnaries";
import Questionnaire from "./components/Questionnaire";
import RequestBalance from "./components/RequestBalance";
import RequestProfit from "./components/RequestProfit";
import PeriodSelection from "./components/PeriodSelection";

export default{
  components: {Dictionnaries, Questionnaire, RequestBalance, RequestProfit, PeriodSelection},
  data : () => ({
    step: 1,
    steps: 3,
    stepTitles: ['questionnaire','dictionaries', 'financial_statements'],
    tab: 0,
    tabItems: [
      { index: 1, tabLabel: "balance", actionClass: "balance" },
      { index: 2, tabLabel: "PROFIT", actionClass: "profit" },
    ],
    //dictionary
    dictRoot: {
      uid: 0,
      title: "root",
      items: [],
    },
    dictTypes: [],
    allowedTypes: ['ACTIVITY', 'ASSETS', 'PASSIVE', 'PROFIT'],
    //fix on the backend
    dictItemsOrder: [
      'Выручка',
      'Себестоимость продаж',
      'Валовая прибыль (убыток)',
      'Административные расходы',
      'в т.ч. амортизация',
      'Расходы по реализации',
      'Прибыль (убыток) от продаж',
      'Проценты к получению',
      'Проценты к уплате',
      'Прочие доходы',
      'Прочие расходы',
      'Прибыль (убыток) до налогообложения',
      'Текущий налог на прибыль',
      'Прочее',
      'Чистая прибыль (убыток)'
    ],
    loading: false,
    questionnaire: {
      attracting: undefined,
      amount: undefined,
      guarantee: undefined,
      company: {
        title: undefined,
        //autocomplete
        type: undefined,
        bin: undefined,
        //учредитель
        fullNameDirector: undefined,
        iinDirector: undefined,
        phone: undefined,
        email: undefined,
        experience: undefined,
        //первый руководитель
        fullNameMainDirector: undefined,
        iinMainDirector: undefined,
        phoneMainDirector: undefined,
        emailMainDirector: undefined,
        experienceMainDirector: undefined,

        activity: undefined,
        accounting: undefined,
        cashlessTurnover: undefined,
      },
    },
    balance: {
      requestUid: undefined,
      headers: [],
      rows: []
    },
    balanceCopy: undefined,
    profit: {
      requestUid: undefined,
      headers: [],
      rows: []
    },
    profitCopy: undefined,
    updateFinancialStatements: false,

    tableHeaders: [],
    period: undefined,
    periodDialog: false,

    activityTypes: [],
    alertDialog: {
      show: false,
      isQuestionnaireNotFilled: false
    },
    isBalanceMatch: false
  }),

  computed:{
    sendRequestInscription(){
      return this.step === 3 && this.tab === 1
    },
  },

  watch: {
    step(val){
      if((val === 2) && !this.$refs.questionnaire.isFormFilled){
        this.step--
        this.alertDialog.show = true
        this.alertDialog.isQuestionnaireNotFilled = true
      }
    },
    loading(value){
      if(value){
        this.$appUtils.setOverlay(true)
      }else{
        this.$appUtils.setOverlay(false)
      }
    },
  },

  async mounted() {
    this.loading = true
    await this.initDict()
    await this.initDictTypes()
    if(this.$router.currentRoute.params && this.$testRegex('uid', this.$router.currentRoute.params.uid)) {
      await this.uploadRequest(this.$router.currentRoute.params.uid)
      await this.initBalance()
    }else await this.updateHeader()
    this.loading = false
    this.balanceUpdating()
  },

  methods:{
    stepBack(){
      if(this.step !== 1 && this.tab === 0) this.step --
      else if(this.step !== 1 && this.tab === 1) this.tab = 0
    },
    closeAlert(){
      this.alertDialog.show = false
      this.alertDialog.isQuestionnaireNotFilled = false
    },
    setLoading(value){
      this.loading = value
    },

    nextStep(){
      if(this.step !== 3) {
        this.tab = 0
        this.step ++
      }
      else if(this.tab === 0) {
        if(!this.isBalanceMatch) {
          this.alertDialog.show = true
          this.alertDialog.isQuestionnaireNotFilled = false
        }
        else this.tab ++
      }
      else {
        this.tab ++
        this.sendRequest(false)
      }
    },

    async uploadRequest(requestUid){
      const resQuestionnarie = await this.$fetch.get('api/request/get?uid=' + requestUid)
      this.questionnaire = resQuestionnarie
      if(this.questionnaire.company.phone){
        this.questionnaire.company.phone = '+7(' + this.questionnaire.company.phone.substring(0,3) + ')-'
            + this.questionnaire.company.phone.substring(3,6) + '-'
            + this.questionnaire.company.phone.substring(6,8) + '-'
            + this.questionnaire.company.phone.substring(8,10)
      }
      if(this.questionnaire.company.phoneMainDirector){
        this.questionnaire.company.phoneMainDirector = '+7(' + this.questionnaire.company.phoneMainDirector.substring(0,3) + ')-'
            + this.questionnaire.company.phoneMainDirector.substring(3,6) + '-'
            + this.questionnaire.company.phoneMainDirector.substring(6,8) + '-'
            + this.questionnaire.company.phoneMainDirector.substring(8,10)
      }


      const resBalance = await this.$fetch.get('api/request/balance/get?requestUid=' + requestUid)
      const resProfit = await this.$fetch.get('api/request/profit/get?requestUid=' + requestUid)
      if(resBalance && resProfit) {
        this.balance = JSON.parse(JSON.stringify(resBalance))
        this.profit = JSON.parse(JSON.stringify(resProfit))
      }
    },

    async initBalance(){
      this.loading = true
      //balance
      this.balanceCopy = JSON.parse(JSON.stringify(this.balance))
      this.balance = {
        requestUid: undefined,
        headers: [],
        rows: []
      }
      if(this.balanceCopy && this.balanceCopy.headers && this.balanceCopy.headers.length && !this.updateFinancialStatements){
        this.balance.headers = this.balanceCopy.headers
      }else this.balance.headers = this.tableHeaders
      await this.initBalanceByType('ASSETS')
      await this.initBalanceByType('PASSIVE')
      //profit
      this.profitCopy = JSON.parse(JSON.stringify(this.profit))
      this.profit = {
        requestUid: undefined,
        headers: [],
        rows: []
      }
      if(this.profitCopy && this.profitCopy.headers && this.profitCopy.headers.length && !this.updateFinancialStatements){
        this.profit.headers = this.profitCopy.headers
      }else this.profit.headers = this.tableHeaders
      await this.initProfit()
      this.loading = false
    },

    //balance
    initBalanceByType(type){
      if(this.dictRoot && this.dictRoot.items && this.dictRoot.items.length){
        let dictByType = this.dictRoot.items.filter(d => {return d.type === type && !d.deleted})
        if(dictByType && dictByType.length){
          //dictName
          this.balance.rows.push({
            title: this.$localize(type),
            fullRow: true,
            class: 'dict-name'
          })
          //dict groups
          dictByType.forEach((d, idx) => {
            if(d && d.items && d.items.length){
              let rowItem = {
                title: d.title,
                parent: d,
                fullRow: true,
                class: 'group-name',
                items: []
              }
              d.items.forEach(dChild => {
                if(dChild.deleted) return
                let childRowItem = { dictionary: dChild, values:[], fullRow: false, class: ''}
                this.balance.headers.forEach(h => {
                  const oldValue = this.balanceCopy && this.balanceCopy.rows && this.balanceCopy.rows.length ? this.searchInBalanceCopy(dChild.uid, h.date) : null
                  let childColItem ={ date: h.date, headerUid: h.uid, value: 0}
                  childRowItem.values.push(oldValue ? oldValue : childColItem)
                })
                rowItem.items.push(childRowItem)
              })
              this.balance.rows.push(rowItem)
              let total = {
                title: this.$localize('section_total'),
                fullRow: false,
                class: 'total-line',
                type: 'total',
                dictUid: d.uid,
                dictType: type,
                values: [],
                idx: idx
              }
              this.balance.headers.forEach(h => {
                total.values.push({date: h.date, headerUid: h.uid, value: 0})
              })
              this.balance.rows.push(total)
            }
          })
        }
        let groupTotal = {
          title: type === 'ASSETS' ? this.$localize('assets_total') : this.$localize('passive_total'),
          fullRow: false,
          class: 'group-total-line',
          type: 'groupTotal',
          dictType: type,
          values: []
        }
        this.balance.headers.forEach(h => {
          groupTotal.values.push({ date: h.date, headerUid: h.uid, value: 0})
        })
        this.balance.rows.push(groupTotal)
      }
    },

    //profit
    initProfit(){
      if(this.dictRoot && this.dictRoot.items && this.dictRoot.items.length){
        let dictByType = this.dictRoot.items.filter(d => {return d.type === 'PROFIT' && !d.deleted})
        if(dictByType && dictByType.length){
          //dictName
          this.profit.rows.push({
            title: this.$localize('PROFIT'),
            fullRow: true,
            class: 'dict-name'
          })
          //dict groups
          dictByType.forEach(d => {
            let childRowItem = { dictionary: d, values:[], fullRow: false, class: ''}
            this.profit.headers.forEach(h => {
              const oldValue = this.profitCopy && this.profitCopy.rows && this.profitCopy.rows.length ? this.searchInProfitCopy(d.uid, h.date) : null
              let childColItem ={ date: h.date, headerUid: h.uid, value:  0}
              childRowItem.values.push(oldValue ? oldValue : childColItem)
            })
            this.profit.rows.push(childRowItem)
          })
        }
      }
    },

    searchInBalanceCopy(dictUid, date){
      let value = null
      if(!this.balanceCopy || !this.balanceCopy.rows) return value
      for(const el of this.balanceCopy.rows){
        if(el.items && el.items.length) {
          for(const i of el.items){
            if (i.dictionary && i.dictionary.uid && i.dictionary.uid === dictUid) {
              if (i.values && i.values.length) {
                for(const v of i.values){
                  if (v.date === date){
                    value = v
                    break;
                  }
                }
              }
            }
            if(value) break;
          }
        }
        if(value) break;
      }
      return value
    },

    searchInProfitCopy(dictUid, date){
      let value = null
      if(!this.profitCopy || !this.profitCopy.rows) return value
      for(const el of this.profitCopy.rows){
        if (el.dictionary && el.dictionary.uid && el.dictionary.uid === dictUid) {
          if (el.values && el.values.length) {
            for(const v of el.values){
              if (v.date === date){
                value = v
                break;
              }
            }
            if(value) break;
          }
        }
        if(value) break;
      }
      return value
    },

    async sendRequest(draft = true ){
      this.loading = true
      //questionnaire
      let clonedQuestionnaire = JSON.parse(JSON.stringify(this.questionnaire))
      if(clonedQuestionnaire.company.phone) clonedQuestionnaire.company.phone = clonedQuestionnaire.company.phone.replace(/[-+()]/g, '').substring(1)
      if(clonedQuestionnaire.company.phoneMainDirector) clonedQuestionnaire.company.phoneMainDirector = clonedQuestionnaire.company.phoneMainDirector.replace(/[-+()]/g, '').substring(1)
      if(clonedQuestionnaire.company.activity) clonedQuestionnaire.company.activity = this.activityTypes.find(el => el.uid === clonedQuestionnaire.company.activity)
      clonedQuestionnaire.status = draft ? 'DRAFT' : 'CREATE'
      let questionnaireRes = await this.$fetch.post('api/request/save', clonedQuestionnaire)
      if(questionnaireRes && questionnaireRes.status && questionnaireRes.status === 'ACCESS'){
        this.questionnaire.uid = questionnaireRes.message
        await this.checkUpdateFinancialStatements()

        let clonedBalance = JSON.parse(JSON.stringify(this.balance))
        clonedBalance.requestUid = questionnaireRes.message
        clonedBalance.rows = clonedBalance.rows.filter(balanceEl => {return (balanceEl.parent && (balanceEl.parent.type ==='ASSETS' || balanceEl.parent.type ==='PASSIVE')) && balanceEl.items && balanceEl.items.length > 0})
        let resBalance = await this.$fetch.post('api/request/balance/save', clonedBalance)

        let clonedProfit = JSON.parse(JSON.stringify(this.profit))
        clonedProfit.requestUid = questionnaireRes.message
        clonedProfit.rows = clonedProfit.rows.filter(profitEl => {return profitEl.values && profitEl.values.length})
        let resProfit = await this.$fetch.post('api/request/profit/save', clonedProfit)

        if(resBalance && resBalance.status && resBalance.status === 'ACCESS' && resProfit && resProfit.status && resProfit.status === 'ACCESS') {
          if(draft) this.$store.dispatch('setError',{ show: true, type: 'success', message: 'saved'})
          else {
            this.$store.dispatch('setError',{ show: true, type: 'success', message: 'request_sent'})
            // this.$router.push('/startup/requests/my')
          }
        }
        this.$router.replace({ name: "startup-request", params: {uid: questionnaireRes.message}})
            .then(() => {
              if(!clonedQuestionnaire.uid) document.location.reload()
            })
            .catch(() =>{})
      }
      this.loading = false
    },

    async checkUpdateFinancialStatements() {
      if (this.updateFinancialStatements && (this.$router.currentRoute.params && this.$testRegex('uid', this.$router.currentRoute.params.uid))) {
        await this.$fetch.post('api/request/balance/delete?requestUid=' + this.$router.currentRoute.params.uid)
        await this.$fetch.post('api/request/profit/delete?requestUid=' + this.$router.currentRoute.params.uid)
        this.updateFinancialStatements = false
      }
    },

    //sums
    balanceUpdating(){
      this.isBalanceMatch = false
      if(this.loading) return
      let dictTotalsSum = []
      if(this.balance && this.balance.rows && this.balance.rows.length) {
        this.balance.rows.forEach(r => {
          if(r.items && r.items.length){
            dictTotalsSum.push({dictUid: r.parent.uid, dictType: r.parent.type, totals: this.getSum(r)})
          }
        })
        if(dictTotalsSum && dictTotalsSum.length){
          dictTotalsSum.forEach(el => {
            let total = this.balance.rows.find(r => (r.type === 'total' && r.dictUid === el.dictUid))
            el.totals.forEach((e, idx) => {
              total.values[idx].value = e
            })
          })

          let assetsTotal = this.totalByDictGroup('ASSETS', dictTotalsSum)
          let passiveTotal = this.totalByDictGroup('PASSIVE', dictTotalsSum)
          if(assetsTotal && assetsTotal.values && assetsTotal.values.length
              && passiveTotal && passiveTotal.values && passiveTotal.values.length){
            for (let i = 0; i < assetsTotal.values.length; i++) {
              if(assetsTotal.values[i].value !== passiveTotal.values[i].value) {
                this.isBalanceMatch = false
                return
              }else this.isBalanceMatch = true
            }
          }
        }
      }
    },

    getSum(item){
      let totals = []
      this.balance.headers.forEach((headEl, innerIdx) => {
        let sum = 0;
        item.items.forEach(el => {
          el.values.forEach(value => {
            if(headEl.date === value.date){
              if(value.value) sum += Number(value.value)
            }
          })
        })
        totals[innerIdx] = sum
      })
      return totals
    },

    totalByDictGroup(type, dictTotalsSum){
      let groupTotal = []
      groupTotal = this.balance.rows.find(r => r.type === 'groupTotal' && r.dictType === type)
      let groupTotalSum = dictTotalsSum.filter(d => d.dictType === type)
      if(groupTotal && groupTotal.values){
        for (let i = 0; i < groupTotal.values.length; i++) {
          let sum = 0
          for (let j = 0; j < groupTotalSum.length; j++) {
            sum += groupTotalSum[j].totals[i]
          }
          groupTotal.values[i].value = sum
        }
      }
      return groupTotal
    },

    //dictionary--------------------------------------------------------------------------------------------------------
    async initDict() {
      let res = await this.$fetch.get('api/dictionary/get-list')
      this.dictRoot.items = res;
      this.sortDictItems();
      this.getActivityTypes()
    },
    sortDictItems(){
      if(this.dictRoot.items && this.dictRoot.items.length > 0){
        //remove deleted by admin
        this.dictRoot.items = this.dictRoot.items.filter(i => !i.isAdmin || (i.isAdmin && !i.deleted) )
        this.dictRoot.items.forEach(i => {
          if(i.items && i.items.length) i.items = i.items.filter(i => !i.isAdmin || (i.isAdmin && !i.deleted))
        })
        this.dictRoot.items = this.dictRoot.items.filter(i => !i.items || (i.items && i.items.length))

        this.dictRoot.items = this.dictRoot.items.map( e => {
          e.sortBy = this.dictItemsOrder.indexOf(e.title) !== -1 ? this.dictItemsOrder.indexOf(e.title) : this.dictRoot.items.length;
          return e;
        });
        this.dictRoot.items.sort( (a,b) => a.sortBy - b.sortBy);
        let last = "Итого";
        this.dictRoot.items.sort(function(x,y){ return x.title == last ? 1 : y.title == last ? -1 : 0; });
      }
    },
    async initDictTypes() {
      this.dictTypes = await this.$fetch.get('api/dictionary/get-types')
      this.dictTypes = this.dictTypes.filter(t => {return this.allowedTypes.indexOf(t) !== -1})
      this.dictTypes.sort((a,b) =>  this.allowedTypes.indexOf(a) - this.allowedTypes.indexOf(b));
    },
    getActivityTypes(){
      this.activityTypes = this.dictRoot.items.filter(e => {return e.type === 'ACTIVITY'})
    },
    async addDictItem(item) {
      this.loading = true
      await this.$fetch.post('api/dictionary/save', item)
      await this.initDict()
      await this.initBalance()
      this.loading = false
    },

    async editDictItem(item) {
      this.loading = true
      await this.$fetch.post('api/dictionary/save', item)
      await this.initDict()
      await this.initBalance()
      this.loading = false
    },

    async removeDictItem(itemUid) {
      this.loading = true
      await this.$fetch.post('api/dictionary/delete?uid=' + itemUid)
      await this.initDict()
      await this.initBalance()
      this.loading = false
    },

    async restoreDictItem(itemUid) {
      this.loading = true
      await this.$fetch.post('api/dictionary/restore?uid=' + itemUid)
      await this.initDict()
      await this.initBalance()
      this.loading = false
    },

    //period------------------------------------------------------------------------------------------------------------
    setPeriod(period){
      this.updateFinancialStatements = true
      this.period = period
      this.tab = 0
    },
    closePeriodDialog(){
      this.periodDialog = false
    },
    async updateHeader(){
      this.loading = true
      if(this.period){
        if(this.period.type !== 'year') this.rebuildTableHeaderByMonth()
        else if(this.period.type === 'year') this.rebuildTableHeaderYear()
      }else this.defaultHeader()
      await this.initBalance()
      this.closePeriodDialog();
      this.loading = false
    },
    defaultHeader(){
      let dateMinusYear = new Date().setFullYear(new Date().getFullYear() -1)
      this.tableHeaders.push(
          {
            date: new Date(dateMinusYear).toISOString().substring(0,10)
          },
          {
            date: new Date().toISOString().substring(0,10)
          })
    },
    rebuildTableHeaderByMonth(){
      const quantity = this.dateDiffInMonth(this.period.dateTo, this.period.dateFrom)
      this.tableHeaders= []
      for (let i = 0; i < quantity + 1; i = this.period.type === 'month' ? i+1 : i+3) {
        let date = new Date(this.period.dateFrom).setMonth((new Date(this.period.dateFrom)).getMonth() + i)
        this.tableHeaders.push(
            {
              date: new Date(date).toISOString().substring(0,10)
            })
      }
    },
    rebuildTableHeaderYear(){
      const quantity = this.dateDiffInMonth(this.period.dateTo, this.period.dateFrom)
      this.tableHeaders= []
      for (let i = 0, j = 0; i < quantity + 1; i+=12) {
        let date = new Date(this.period.dateFrom).setFullYear((new Date(this.period.dateFrom)).getFullYear() + j)
        this.tableHeaders.push(
            {
              date: new Date(date).toISOString().substring(0,10)
            })
        j++;
      }
    },
    dateDiffInMonth(toDate, fromDate){
      let months;
      months = (new Date(toDate).getFullYear() - new Date(fromDate).getFullYear()) * 12;
      months -= new Date(fromDate).getMonth();
      months += new Date(toDate).getMonth();
      return months <= 0 ? 0 : months;
    },
    // getGroupTotalBalanceSum(type){
    //   let totals = []
    //   this.balance.headers.forEach((headEl, innerIdx) => {
    //     let sum = 0;
    //     this.balance.rows.forEach(el=> {
    //       if (el.items && el.parent.type === type) {
    //         el.items.forEach(childEl => {
    //           childEl['values'].forEach(value => {
    //             if(headEl.date === value.date){
    //               if(value.value) sum += Number(value.value)
    //             }
    //           })
    //         })
    //       }
    //     })
    //     totals[innerIdx] = sum
    //   })
    //   return totals
    // },
  }
}
</script>
