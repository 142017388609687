<template>
  <div class="view-wrapper">
    <v-card>
      <v-card-title class="pa-0">
        <v-tabs
            ref="tabs"
            v-model="tab"
            style="position:sticky; top: 0; z-index: 1"
            class="mt-0 pt-0"
            show-arrows
            color="success"
        >
          <v-tab
              v-for="item in types"
              :key="item.index"
          >
            {{ $localize(item) }}
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text class="overflow-auto" style="height: calc(100vh - 164px);">
        <v-tabs-items v-model="tab" class="py-2">
          <v-tab-item v-for="type in types"
                      :key="type"
          >
            <v-row class="justify-start mx-0">
              <v-col cols="12" md="8" class="pa-0 ma-0">
                <DictTreeView
                    :root="getRoot"
                    @dialogAdd="openDialogAdd"
                    @menuClick="menuClick"
                />
              </v-col>
              <v-col cols="12" md="4" class="pa-0 ma-0"></v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
        <div class="bottom-btn mt-2">
          <v-btn text
                 outlined
                 style="width: 100%"
                 @click="openDialogAdd(item)"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-dialog v-model="menuItem.dialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">{{ dialogProp.title }}</v-card-title>
          <v-card-text v-if="menuItem.type !== 'remove' && menuItem.type !== 'restore'" class="pa-2">
            <v-text-field
                v-model="item.title"
                :rules="[rules.required]"
                :readonly="menuItem.type === 'details'"
                outlined
                dense
                label="Значение"
                class="mb-0"
                hide-details
                @keydown.enter="ok"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDialog">{{$localize('cancel')}}</v-btn>
            <v-btn
                :outlined="!isFormFilled"
                color="success"
                @click="ok">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import DictTreeView from "../../dictionaries/components/DictTreeView";

export default {
  components: {DictTreeView},
  props: {
    root:{
      type: Object,
      default: () => {}
    },
    types:{
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: [],
      menuItem: {
        dialog: false,
        type: undefined,
      },
      tab: undefined,
      item: {
        parentUid: undefined,
        type: undefined,
        title: undefined,
        uid: undefined,
      },
      rules: {
        required: value => !!value || this.$localize('mandatory_field'),
      }
    }
  },

  computed: {
    getRoot() {
      let tabRoot = this.$lodash.clone(this.root)
      tabRoot.items = this.root.items.filter(el => {
        return el.type === this.types[this.tab]
      })
      this.clearRemovedChild(tabRoot.items)
      return tabRoot
    },

    dialogProp() {
      switch (this.menuItem.type) {
        case 'add' :
          return {title: this.$localize('add_dictionary')}
        case 'edit' :
          return {title: this.$localize('edit_dictionary')}
        case 'remove' :
          return {title: this.$localize('are_you_sure_you_want_to_delete')}
        case 'restore' :
          return {title: this.$localize('are_you_sure_you_want_to_restore')}
        case 'details' :
          return {title: this.$localize('detailed_information')}
        default :
          return {title: ''}
      }
    },

    isFormFilled() {
      return !!this.item.title
    },

    // showAddButton(){
    //   return this.types[this.tab] !== 'ASSETS' && this.types[this.tab] !== 'PASSIVE'
    // }
  },

  methods: {
    clearRemovedChild(arr) {
      arr.forEach(el => {
        if (el.items && el.deleted) el.items = []
        else if (el.items) this.clearRemovedChild(el.items)
      })
    },
    ok() {
      if (this.isFormFilled) return
      switch (this.menuItem.type) {
        case 'add' :
          this.addItem()
          break
        case 'edit' :
          this.editItem()
          break
        case 'remove' :
          this.removeItem()
          break
        case 'details' :
          this.closeDialog()
          break
        case 'restore' :
          this.restoreItem()
          break
      }
    },
    menuClick(menu, item) {
      switch (menu.action) {
        case 'add':
          return this.openDialogAdd(item)
        case 'edit':
          return this.openDialogEdit(item)
        case 'details':
          return this.openDialogDetails(item)
        case 'remove':
          return this.openDialogRemove(item)
        case 'restore':
          return this.openDialogRestore(item)
      }
    },

    openDialogAdd(item) {
      const cloned = this.$lodash.clone(item);
      this.item.parentUid = cloned.uid
      this.item.type = cloned.type
      this.menuItem.type = 'add'
      this.menuItem.dialog = true
    },

    async addItem() {
      this.item.type = this.types[this.tab]
      await this.$emit('addItem', this.item)
      await this.closeDialog()
    },

    openDialogEdit(item) {
      this.item = this.$lodash.clone(item);
      this.menuItem.type = 'edit'
      this.menuItem.dialog = true
    },

    async editItem() {
      await this.$emit('editItem', this.item)
      await this.closeDialog()
    },

    openDialogRemove(item) {
      this.item.uid = item.uid
      this.menuItem.type = 'remove'
      this.menuItem.dialog = true
    },

    async removeItem() {
      await this.$emit('removeItem', this.item.uid)
      await this.closeDialog()
    },

    openDialogRestore(item) {
      this.item.uid = item.uid
      this.menuItem.type = 'restore'
      this.menuItem.dialog = true
    },

    async restoreItem() {
      await this.$emit('restoreItem', this.item.uid)
      await this.closeDialog()
    },

    openDialogDetails(item) {
      this.item = this.$lodash.clone(item);
      this.menuItem.type = 'details'
      this.menuItem.dialog = true
    },

    async closeDialog() {
      this.menuItem.dialog = false
      this.menuItem.type = undefined
      this.clearNewItem()
    },

    clearNewItem() {
      this.item.parentUid = undefined
      this.item.type = undefined
      this.item.title = undefined
      this.item.uid = undefined
    },

    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  }
}
</script>