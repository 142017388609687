<template>
  <v-card class="view-wrapper">
    <v-card-title class="justify-center pt-3 pb-1"><h3>{{ $localize('fill_out_the_questionnaire') }}</h3></v-card-title>
    <v-card-text class="overflow-auto input-number-without-spin-button pb-2" style="height: calc(100vh - 136px);">
      <v-container class="width-adaptive-container">
        <v-form ref="myForm">
          <v-text-field v-model="item.company.title"
                        :label= "$localize('full_company_name')"
                        :rules="[rules.required]"
                        outlined
                        dense
          ></v-text-field>
          <v-text-field v-model="item.company.bin"
                        :label= "$localize('bin_of_the_company')"
                        :rules="[rules.required, rules.bin]"
                        return-masked-value
                        v-mask="'############'"
                        outlined
                        dense
          ></v-text-field>
          <v-autocomplete v-model="item.company.type"
                          :items="companyTypes"
                          item-value="key"
                          item-text="title"
                          :label= "$localize('company_type')"
                          :rules="[rules.required]"
                          outlined
                          dense
          ></v-autocomplete>
          <!--        //учредитель-->
          <v-text-field v-model="item.company.fullNameDirector"
                        :label= "$localize('full_name_of_the_founder_s')"
                        :rules="[rules.required]"
                        outlined
                        dense
          ></v-text-field>
          <v-text-field v-model="item.company.iinDirector"
                        v-mask="'############'"
                        :label= "$localize('iin_of_the_founder')"
                        :rules="[rules.required, rules.iin]"
                        outlined
                        dense
          ></v-text-field>
          <v-text-field v-model="item.company.experience"
                        type="number"
                        :label= "$localize('work_experience_of_the_founder_s__max_months')"
                        outlined
                        dense
          ></v-text-field>
          <v-text-field v-model="item.company.phone"
                        :label= "$localize('founder_s_phone')"
                        prepend-inner-icon="mdi-phone"
                        return-masked-value
                        v-mask="'+7(###)-###-##-##'"
                        outlined
                        dense
          ></v-text-field>
          <v-text-field v-model="item.company.email"
                        :label= "$localize('founder_email')"
                        outlined
                        dense
          ></v-text-field>
          <!--      первый руководитель-->
          <v-text-field v-model="item.company.fullNameMainDirector"
                        :label= "$localize('full_name_of_the_first_head')"
                        outlined
                        dense
          ></v-text-field>
          <v-text-field v-model="item.company.iinMainDirector"
                        return-masked-value
                        v-mask="'############'"
                        :label= "$localize('iin_of_the_first_head')"
                        outlined
                        dense
          ></v-text-field>
          <v-text-field v-model="item.company.experienceMainDirector"
                        type="number"
                        :label= "$localize('work_experience_of_the_first_head_months')"
                        outlined
                        dense
          ></v-text-field>
          <v-text-field v-model="item.company.phoneMainDirector"
                        :label= "$localize('cell_phone_of_the_first_head')"
                        prepend-inner-icon="mdi-phone"
                        return-masked-value
                        v-mask="'+7(###)-###-##-##'"
                        outlined
                        dense
          ></v-text-field>
          <v-text-field v-model="item.company.emailMainDirector"
                        :label= "$localize('email_of_the_first_manager')"
                        outlined
                        dense
          ></v-text-field>
          <v-autocomplete v-model="item.company.activity"
                          :items="activityTypes"
                          item-text="title"
                          item-value="uid"
                          :label= "$localize('field_of_activity')"
                          outlined
                          dense
          ></v-autocomplete>
          <vuetify-money v-model="item.company.cashlessTurnover"
                         :label= "$localize('cashless_turnover_in_the_company_tenge')"
                         :options="{prefix: 'KZT', precision: 0}"
                         outlined
                         dense
          ></vuetify-money>
          <v-autocomplete v-model="item.company.accounting"
                          :items="companyAccountingItems"
                          item-value="key"
                          item-text="title"
                          :label= "$localize('what_is_the_accounting')"
                          outlined
                          dense
          ></v-autocomplete>
          <v-autocomplete v-model="item.attracting"
                          :items="attractingTypes"
                          item-value="key"
                          item-text="title"
                          :label= "$localize('the_purpose_of_attracting')"
                          outlined
                          dense
          ></v-autocomplete>
          <vuetify-money v-model="item.amount"
                         :label= "$localize('requested_amount_tenge')"
                         :options="{prefix: 'KZT', precision: 0}"
                         outlined
                         dense
          ></vuetify-money>
          <v-autocomplete v-model="item.guarantee"
                          :items="attractingItems"
                          item-value="key"
                          item-text="title"
                          :label= "$localize('providing_a_personal_guarantee_to_the_founder_s')"
                          outlined
                          dense
          ></v-autocomplete>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
// const binRegex = new RegExp(/\d{12,}$/);

export default{
  props:{
    itemProp: {
      type: Object,
      default: () => {},
    },
    activityTypes: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      rules: {
        required: value => !!value || this.$localize('mandatory_field'),
        email: value => {
          return this.$testRegex('email', value) || this.$localize('enter_email')
        },
        iin: value => {
          return this.$testRegex('iin', value) || this.$localize('enter_iin')
        },
        bin: value => {
          return this.$testRegex('iin', value) || this.$localize('enter_bin')
        },
        // bin: value => {
        //   return binRegex.test(value) || 'Введите бин'
        // },
      },
      item: this.itemProp,
      attractingTypes: [],
      companyTypes: [
        {key: 'IE', title: this.$localize('IE')},
        {key: 'LLP', title: this.$localize('LLP')},
      ],
      companyAccountingItems: [
        {key: '1c', title: this.$localize('1С')},
        {key: 'excel', title: this.$localize('Excel')},
        {key: 'other', title: this.$localize('other')},
      ],
      attractingItems: [{key: true, title: this.$localize('yes')}, {key: false, title: this.$localize('no')}],
    }
  },

  computed: {
    isFormFilled() {
      return this.$refs.myForm.validate()
    }
  },
  //     for (let key in this.item){
  //       if(!this.item[key]) return false
  //     }
  //     for(let companyKey in this.item.company){
  //       if(!this.item.company[companyKey]) return false
  //       else {
  //         switch (companyKey){
  //           case 'bin' : if(this.item.company[companyKey].length !== 12) return false; break
  //           case 'iinDirector' : if(this.item.company[companyKey].length !== 12) return false; break
  //           case 'phone' : if(this.item.company[companyKey].length < 17) return false; break
  //           case 'email' : if(!this.$testRegex('email', this.item.company[companyKey])) return false; break
  //           case 'iinMainDirector' : if(this.item.company[companyKey].length !== 12) return false; break
  //           case 'phoneMainDirector' : if(this.item.company[companyKey].length < 17) return false; break
  //           case 'emailMainDirector' : if(!this.$testRegex('email',this.item.company[companyKey])) return false; break
  //         }
  //       }
  //     }
  //     return true
  //   },
  // },

  watch:{
    itemProp: {
      deep: true,
      handler(n, o) {
        if(JSON.parse(JSON.stringify(n)) !== JSON.parse(JSON.stringify(o))) {
          this.item = n
        }
      },
    }
  },

  mounted() {
    this.initAttractingTypes()
  },

  methods:{
    async initAttractingTypes() {
      const res = await this.$fetch.get('api/request/get-attracting-types')
      if(res) {
        res.forEach(el => {
          this.attractingTypes.push({key: el, title: this.$localize(el)})
        })
      }
    },
  }
}
</script>
