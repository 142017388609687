<template>
  <v-treeview
      :active.sync="active"
      :items="root.items"
      item-children="items"
      item-text="title"
      item-key="uid"
      color="success"
      open-on-click
      transition
  >
    <template v-slot:prepend="{ item }">
      <v-icon v-if="!item.items">
        mdi-book-open
      </v-icon>
    </template>
    <template v-slot:append="{ item }">
      <v-tooltip left v-if="item.deleted" >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs"
                  v-on="on"
          >
            mdi-bookmark-remove-outline
          </v-icon>
        </template>
        <span>{{ $localize('deleted') }}</span>
      </v-tooltip>
      <v-menu v-if="isLocked(item)"
              bottom
              left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-list dense class="py-0">
          <v-list-item
              v-for="(menu, i) in item.deleted ? menuItemsRemoved : menuItemsExist"
              :key="i"
              link
              @click="menuClick(menu, item)"
          >
            <v-list-item-title>{{ $localize(menu.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
<!--      <v-icon v-else class="px-2">mdi-lock-outline</v-icon>-->
    </template>
  </v-treeview>
</template>

<script>
export default{
  name: "DictTreeView",
  props: ['root', 'type'],
  data : () => ({
    active: [],

    menuItemsExist: [
      {title: 'add', action: "add"},
      {title: 'edit', action: "edit"},
      {title: 'details', action: "details"},
      {title: 'delete', action: "remove"},
    ],
    menuItemsRemoved: [
      {title: 'details', action: "details"},
      {title: 'restore', action: "restore"},
    ],

    item: {
      parentUid: undefined,
      type: undefined,
      title: undefined,
      uid: undefined,
    },
  }),

  methods:{
    openDialogAdd(item) {
      this.$emit('dialogAdd', item)
    },

    menuClick(menu, item){
      this.$emit('menuClick', menu, item)
    },
    isLocked(item){
      return !item.isAdmin
    }
  }
}
</script>
