<template>
  <div v-if="loading">{{$localize('loading')}}</div>
  <v-simple-table v-else
                  fixed-header
                  dense
                  mobile-breakpoint="0"
                  style="height: 100%;"
                  class="table-with-borders"
  >
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-center">
          {{  $localize('indicator_name') }}
        </th>
        <template>
          <template v-for="(h, h_idx) in profit.headers">
            <th :key="'ha' + h_idx">{{ $dateFormat(h.date) }}</th>
          </template>
        </template>
      </tr>
      </thead>
      <tbody>
      <template v-for="(r, r_idx) in profit.rows">
        <tr :key="'r' + r_idx">
          <td :class="r.dictionary && r.dictionary.title === 'Итого' ? 'group-total-line' : r.class"
              :colspan="r.fullRow ? profit.headers.length + 1 : ''"
          >{{ r.fullRow ? r.title : r.dictionary.title}}
          </td>
          <template v-if="!r.fullRow">
            <template v-for="(rh, rh_idx) in profit.headers">
              <td :key="'r' + r_idx + 'rh' + rh_idx"
                  :class="r.dictionary && r.dictionary.title === 'Итого' ? 'group-total-line' : rh.class"
                  @keydown.enter="focusNext($event, rh_idx)">
                <money v-model.lazy="profit.rows[r_idx].values[rh_idx].value"
                       :rules="[rules.required]"
                       :precision="0"
                       dense
                ></money>
              </td>
            </template>
          </template>
        </tr>
      </template>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    profitProp: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      profit: this.profitProp,

      rules: {
        required: value => !!value || this.$localize('mandatory_field')
      },
    }
  },

  watch: {
    // dictionaries: {
    //   deep: true,
    //   handler(n, o) {
    //     if(JSON.parse(JSON.stringify(n)) !== JSON.parse(JSON.stringify(o))) {
    //       console.log('handler here!')
    //       this.dictionaries = n
    //     }
    //   },
    // },
    profitProp: {
      deep: true,
      handler(n, o) {
        if(JSON.parse(JSON.stringify(n)) !== JSON.parse(JSON.stringify(o))) {
          this.profit = n
        }
      },
    },

  },

  methods:{
    focusNext(e, idx){
      this.focusNext_(e.target.closest("tr").nextElementSibling, idx)
    },

    focusNext_(row, idx) {
      let inp
      if (row) inp = row.getElementsByTagName("input")
      if (inp && inp.length) {
        inp = inp[idx]
        if(inp) inp.focus()
      } else if(row) this.focusNext_(row.closest("tr").nextElementSibling, idx)
    },
  }
}
</script>
