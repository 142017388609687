<template>
  <v-card>
    <v-card-title class="text-h5">{{ $localize('choose_the_period') }}</v-card-title>
    <v-card-text class="mb-0 pb-0">
      <v-container>
        <v-select
            v-model="period.type"
            :items="periodTypes"
            item-text="label"
            item-value="type"
            :label="$localize('type')"
        ></v-select>
        <v-row v-show="period.type">
          <v-menu
              v-model="fromDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="period.dateFrom"
                  label="С"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="period.dateFrom"
                type="month"
                min="1900-01-01"
                :max="new Date().toISOString().slice(0, 10)"
                no-title
                @input="fromDateMenu = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
              v-model="toDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="period.dateTo"
                  label="По"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="period.dateTo"
                type="month"
                min="1900-01-01"
                :max="new Date().toISOString().slice(0, 10)"
                no-title
                @input="toDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="cancel">{{ $localize('cancel') }}</v-btn>
      <v-btn
          :disabled="!isPeriodFormFilled"
          color="success"
          @click="ok">OK
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default{
  name: "PeriodMenuCard",
  props: ['setCancel', 'setOk'],
  data() {
    return {
      periodTypes: [
        {type: 'month', label: this.$localize('monthly')},
        {type: 'quarter', label: this.$localize('quarterly')},
        {type: 'year', label: this.$localize('yearly')},
      ],
      fromDateMenu: false,
      toDateMenu: false,

      period: {
        type: undefined,
        dateFrom: undefined,
        dateTo: undefined,
        dateDiffInMonth: undefined,
      }
    }
  },

  computed:{
    isPeriodFormFilled() {
      if (!this.period.type || !this.period.dateFrom || !this.period.dateTo) return false
      if (this.period.dateTo <= this.period.dateFrom) return false
      switch (this.period.type) {
        case 'month' :
          this.dateDiffInMonth(this.period.dateTo, this.period.dateFrom)
          break
        case 'quarter' :
          if (this.dateDiffInMonth(this.period.dateTo, this.period.dateFrom) < 3) return false
          break
        case 'year' : if (this.dateDiffInMonth(this.period.dateTo, this.period.dateFrom) < 12) return false
          break
      }
      return true;
    },

    pickerType(){
      switch (this.period.type) {
        case 'month' :
          return 'month'
        case 'quarter' :
          return 'month'
        case 'year' :
          return 'year'
        default :
          return 'date'
      }
    },
  },

  methods:{
    dateDiffInMonth(toDate, fromDate){
      let months;
      months = (new Date(toDate).getFullYear() - new Date(fromDate).getFullYear()) * 12;
      months -= new Date(fromDate).getMonth();
      months += new Date(toDate).getMonth();
      return months <= 0 ? 0 : months;
    },

    async ok(){
      await this.$emit('setPeriod', this.period);
      await this.setOk()
      // this.clearPeriod()
    },

    async cancel(){
      this.clearPeriod()
      this.$emit('setPeriod', this.period);
      this.setCancel()
    },

    clearPeriod(){
      this.period.type = undefined
      const currentDate = new Date()
      this.period.dateFrom = currentDate.getFullYear()-1 + '-01'
      this.period.dateTo= currentDate.getFullYear() + '-01'
      this.period.dateDiffInMonth= undefined
    },
  }
}
</script>
